@import '~normalize.css/normalize.css';
@import '~overlayscrollbars/css/OverlayScrollbars.css';

@import './hamburgers/hamburgers';

@import './mixins/responsive-font';

@import 'src/lib/videoJs/vjs-ext';
@import 'src/lib/videoJs/vjs-theme-pfp';

//html {
//  @include responsive-font(0.83vw, 16px, false, 16px);
//}

* {
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  color: #343f4e;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
}
