.video-js.vjs-theme-pfp {
  --vjs-theme-pfp--primary: #b71833;
  --vjs-theme-pfp--secondary: #fff;

  &.vjs-has-started,
  &.vjs-playing {
    .pfp-big-play-button {
      display: none;
    }
  }

  &.vjs-has-started {
    .pfp-big-play-button {
      background-color: rgba(#343f4e, 0.8);
    }
  }

  .vjs-control-bar {
    background: none;
    background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
    height: 70px;
    padding-top: 20px;
  }

  .vjs-control,
  .vjs-time-divider {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .vjs-button > .vjs-icon-placeholder::before {
    line-height: 50px;
  }

  .vjs-play-progress::before {
    display: none;
  }

  .vjs-progress-control {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 20px;
  }

  .vjs-progress-control .vjs-progress-holder {
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }

  .vjs-play-progress {
    background-color: var(--vjs-theme-pfp--primary);
  }

  .vjs-load-progress div {
    background-color: #fff;
  }

  .vjs-remaining-time {
    line-height: 50px;
    flex: 3;
    text-align: left;
  }

  /* Volume stuff */
  .vjs-volume-panel {
    align-items: center;
  }

  /* Time related controls */
  .vjs-time-divider {
    justify-content: center;
  }

  .vjs-time-control {
    padding-left: 0;
    padding-right: 0;
    font-size: 0.75rem;
  }

  /* Playback rate related controls */
  .vjs-playback-rate .vjs-playback-rate-value {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
  }

  .vjs-playback-rate .vjs-menu {
    top: 15px;
    bottom: unset;
    margin-bottom: unset;
  }

  /* Spacer rate related controls */
  .vjs-control-bar .vjs-spacer {
    display: flex;
    flex: 1;
  }

  .vjs-poster {
    cursor: default;

    &:before {
      background-color: rgba(#343f4e, 0.8);
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .vjs-seek-backward-control,
  .vjs-seek-forward-control {
    img {
      width: 26px;
    }
  }

  .pfp-big-play-button {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &:focus {
      text-shadow: none;
    }

    img {
      width: 64px;
    }

    span {
      color: #ffffff;
      font-family: 'Open Sans', sans-serif;
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      margin-top: 10px;
    }
  }
}
