.video-js .vjs-control-bar .vjs-current-time,
.video-js .vjs-control-bar .vjs-duration,
.video-js .vjs-control-bar .vjs-time-divider {
  display: block;
}

/* refer to: https://github.com/videojs/font for customization */
.video-js .vjs-seek-backward-control .vjs-icon-placeholder,
.video-js .vjs-seek-forward-control .vjs-icon-placeholder {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
}
.video-js .vjs-seek-backward-control .vjs-icon-placeholder:before {
  content: '\f120';
}

.video-js .vjs-seek-forward-control .vjs-icon-placeholder:before {
  content: '\f11f';
}

.video-js .vjs-user-watermark {
  container-type: size;
  font-family: "Slabo 13px", serif;
  font-weight: normal;
  left: 0;
  line-height: 1.24;
  opacity: 0.3;
  overflow-wrap: anywhere;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.video-js .vjs-user-watermark-text {
  font-size: clamp(0.5rem, 3cqmax, 2.25rem);
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
